import * as React from 'react';
import { Form, Input, Typography } from 'antd';
import {
  VERIFICATION_HINT_MESSAGE_RECOVERY,
  VERIFY_CODE_NAME_IS_REQUIRED,
  VERIFY_CODE,
  CHECK_SPAM,
  SECURITY_CHECK,
  RECAPTHCA_IS_REQUIRED,
} from 'constants';

import * as config from 'config/config';

import CaptchaForm from 'component/form/captcha';

const { Text } = Typography;
const VerifyCodeForm = ({
  text,
  value = '',
  state = {},
  needCaptcha,
  validateCodeForm,
  refCaptcha,
}) => {
  const forEmail = Boolean(
    value.toString() === '1' || value.toString() === '3'
  );
  const handleVerificationSuccess = (token, ekey) => {
    validateCodeForm.current.form.setFieldsValue({ captcha: token });
  };
  return (
    <>
      <Text className={'display-block contact-address'}>
        {text || VERIFICATION_HINT_MESSAGE_RECOVERY}
      </Text>
      <Text className={'display-block contact-address'} strong>
        {forEmail ? `${state.phoneX}` : `${state.email}`}
      </Text>
      <Form.Item
        name="verifyCode"
        label={<span className="required">{VERIFY_CODE}</span>}
        style={{ marginBottom: '0.5rem' }}
        rules={[
          {
            required: true,
            message: VERIFY_CODE_NAME_IS_REQUIRED,
          },
        ]}>
        <Input
          autoFocus={true}
          autoComplete="off"
          autoCorrect="false"
          autoCapitalize="off"
          spellCheck="false"
          placeholder={VERIFY_CODE}
        />
      </Form.Item>
      {!forEmail && <Text className={'display-block'}>{CHECK_SPAM}</Text>}

      {config.SITEKEY_CAPTCHA && needCaptcha && (
        <Form.Item
          name="captcha"
          label={SECURITY_CHECK}
          rules={[
            {
              required: true,
              message: RECAPTHCA_IS_REQUIRED,
            },
          ]}>
          <CaptchaForm
            refCaptcha={refCaptcha}
            handleVerificationSuccess={handleVerificationSuccess}
          />
        </Form.Item>
      )}
    </>
  );
};

export default VerifyCodeForm;
